import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { links } from '@/config/links';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useConnectAPI } from '../hooks/useConnectAPI';
import useCreateTaskAPI from '../hooks/useCreateTaskAPI';
import { CreateTaskAPISteps, EvaluationFields } from '../types/task-apis.enum';
import { ResetValues } from '../types/task-apis.type';
import { ApiDetailsV2 } from './ApiDetailsV2';
import CurlParser from './CurlParser';
import EvaluateResponse from './EvaluateResponse';
import { PayloadDetails } from './PayloadDetails';
import { TestAPI } from './TestAPI';

const ConnectAPIDialog = ({ open, setOpen }: { open: boolean; setOpen: any }) => {
	const {
		setFormFields,
		isLoading,
		step,
		setStep,
		formFields,
		formErrors,
		setFormErrors,
		parsedData,
		setParsedData,
		evaluationErrors,
		evaluatedFields,
		setEvaluationErrors,
		setEvaluatedFields,
		dialogMaxWidth,
		setDialogMaxWidth,
	} = useConnectAPI();

	const {
		prevStepMap,
		handleClick,
		handleStartOver,
		getButtonLabel,
		getDescription,
	} = useCreateTaskAPI({
		setOpen,
	});

	const handleStepRegression = (currentStep: CreateTaskAPISteps) => {
		const prevStep = prevStepMap[currentStep];

		if (
			currentStep === CreateTaskAPISteps.EvaluateResponse ||
			currentStep === CreateTaskAPISteps.Upsert
		) {
			return CreateTaskAPISteps.Testing;
		}

		return prevStep;
	};

	const getStateResetValues = (currentStep: CreateTaskAPISteps): ResetValues => {
		const baseReset: ResetValues = {
			isApiTestDone: false,
			isApiSchemaValid: false,
		};

		switch (currentStep) {
			case CreateTaskAPISteps.EvaluateResponse:
				return {
					...baseReset,
					evaluationErrors: {},
					evaluatedFields: {},
				};
			case CreateTaskAPISteps.Testing:
				return {
					...baseReset,
					parsedData: null,
				};
			case CreateTaskAPISteps.Upsert:
				return baseReset;
			default:
				return baseReset;
		}
	};

	const goBack = () => {
		const prevStep = handleStepRegression(step);

		if (prevStep) {
			// Reset relevant state based on current step
			const resetValues = getStateResetValues(step);

			setStep(prevStep);

			// Reset form fields and other state
			setFormFields((prev: any) => ({
				...prev,
				...resetValues,
			}));
			setDialogMaxWidth('35rem');

			// Reset additional states if needed
			if (resetValues.evaluationErrors) {
				setEvaluationErrors({});
			}
			if (resetValues.evaluatedFields) {
				setEvaluatedFields({});
			}
			if (resetValues.parsedData) {
				setParsedData(null);
			}
		}
	};

	const isTesting = useMemo(() => {
		return (
			step === CreateTaskAPISteps.Testing ||
			step === CreateTaskAPISteps.ReTesting ||
			step === CreateTaskAPISteps.ReadyToSave
		);
	}, [step]);

	const isDisabled = useMemo(() => {
		if (step === CreateTaskAPISteps.EvaluateResponse) {
			// Check if any evaluationErrors key has error
			const hasErrors = Object.values(evaluationErrors).some(
				(error) => !!error,
			);

			const requiredFields = [EvaluationFields.DataResult];

			if (formFields?.isMetricBasedTaskEnabled) {
				requiredFields.push(
					EvaluationFields.DataMetric,
					EvaluationFields.DataMetricDataType,
				);
			}

			const allRequiredFieldsEvaluated = requiredFields.every(
				(field) => evaluatedFields[field],
			);

			return hasErrors || !allRequiredFieldsEvaluated || isLoading;
		}
		return isLoading;
	}, [
		evaluationErrors,
		evaluatedFields,
		isLoading,
		step,
		formFields?.isMetricBasedTaskEnabled,
	]);

	const showToolTip = useMemo(() => {
		return step === CreateTaskAPISteps.EvaluateResponse && isDisabled;
	}, [isDisabled, step]);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent
				style={{ maxWidth: dialogMaxWidth ?? '35rem' }}
				className="sm:max-w-full"
			>
				<DialogHeader className="border-b pb-3">
					<DialogTitle className="flex justify-between">
						{isTesting ? 'Test with your data' : 'Connect your API'}
						<Link
							to={links?.docs?.apiCreation}
							target="_blank"
							className="text-sm font-normal text-normal text-muted-foreground hover:text-primary/80 hover:underline"
						>
							<i className="bi-question-circle me-1"></i>Guidelines
						</Link>
					</DialogTitle>
					<DialogDescription>{getDescription}</DialogDescription>
				</DialogHeader>
				<div className="max-h-[30rem] overflow-auto px-1">
					{step === CreateTaskAPISteps.Basic && <ApiDetailsV2 />}
					{step === CreateTaskAPISteps.Curl && (
						<CurlParser
							formFields={formFields}
							setFormFields={setFormFields}
							formErrors={formErrors}
							setFormErrors={setFormErrors}
							parsedData={parsedData}
							setParsedData={setParsedData}
						/>
					)}
					{step === CreateTaskAPISteps.Payload && <PayloadDetails />}
					{isTesting && <TestAPI setStep={setStep} />}
					{step === CreateTaskAPISteps.EvaluateResponse && (
						<EvaluateResponse />
					)}
					{step === CreateTaskAPISteps.Upsert && <EvaluateResponse />}
				</div>
				<DialogFooter
					className={`justify-between space-x-2 border-t pt-3 flex sm:justify-between `}
				>
					{step !== CreateTaskAPISteps.ReTesting &&
					step !== CreateTaskAPISteps.Basic ? (
						<div className="flex">
							<Button variant="outline" onClick={() => goBack()}>
								Back
							</Button>
						</div>
					) : (
						<div></div>
					)}
					<div className="flex gap-2">
						<Button variant="ghost" onClick={() => handleStartOver()}>
							Cancel
						</Button>
						<TooltipWrapper
							tooltip={
								showToolTip ? 'Evaluate the expressions first' : ''
							}
							align="end"
						>
							<Button
								onClick={() => handleClick()}
								disabled={isDisabled}
							>
								{isLoading ? (
									<i className="bi bi-arrow-clockwise animate-spin mr-1 text-sm"></i>
								) : null}
								{getButtonLabel}
							</Button>
						</TooltipWrapper>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default ConnectAPIDialog;
