import { Badge } from '@/components/ui/badge';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { links } from '@/config/links';
import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useConnectAPI } from '../hooks/useConnectAPI';
import useTestAPI from '../hooks/useTestAPI';
import { ApiDataEnums, iconKeyMap } from '../types/task-apis.enum';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';

export const TestAPI = ({ setStep }) => {
	const { formErrors, formFields, setFormErrors } = useConnectAPI();

	const { handlePayloadInputTestValue } = useTestAPI();

	// const { minStartTimestamp, endDate } = useEventTimestamp({
	// 	startImmediately: true,
	// 	startDate: new Date(),
	// });

	const combinedPayload = useMemo(() => {
		const apiConstantDataFields = Array.isArray(
			formFields?.apiConstantDataFields,
		)
			? formFields?.apiConstantDataFields
			: [];

		const constantKeys = new Set(
			apiConstantDataFields.map((item) => item?.key).filter(Boolean),
		);

		const apiPayloadWithFlag = Array.isArray(formFields?.apiPayload)
			? formFields?.apiPayload
					.filter((item) => item && !constantKeys.has(item.key))
					.map((item) => ({
						...item,
						isConstant: false,
					}))
			: [];

		const constantDataWithFlag = formFields?.isCustomConstantPayloadRequired
			? apiConstantDataFields
					.filter((item) => item && item.key)
					.map((item) => ({
						...item,
						isConstant: true,
					}))
			: [];

		return [...apiPayloadWithFlag, ...constantDataWithFlag];
	}, [
		formFields?.apiConstantDataFields,
		formFields?.apiPayload,
		formFields?.isCustomConstantPayloadRequired,
	]);

	useEffect(() => {
		setFormErrors({});
	}, [formFields?.apiPayload, setFormErrors]);

	return (
		<div>
			<div className="w-full">
				{combinedPayload?.length > 0
					? combinedPayload?.map((item: any, index: number) => (
							<div key={item.key} className="my-4">
								<div
									className={clsx(
										'flex items-center border rounded-lg overflow-none',
										formErrors[item.key]
											? 'border-destructive'
											: '',
									)}
								>
									<div className="border-e h-9 flex items-center bg-muted rounded-s-md min-w-[157.7px]">
										<TooltipWrapper
											tooltip={item?.key}
											align="start"
										>
											<Label className="p-2 flex items-center text-black/70 max-w-[9rem] truncate">
												<i
													className={`bi-${iconKeyMap[item.value] || 'braces-asterisk'} me-2 text-black/70`}
												></i>
												{item?.key}
											</Label>
										</TooltipWrapper>
									</div>
									{item?.key ===
										ApiDataEnums?.EventStartTimestamp ||
									item?.key === ApiDataEnums?.EventEndTimestamp ? (
										<div className="px-3 text-sm">
											{item.key}
										</div>
									) : (
										<Input
											className={clsx(
												'border-none shadow-none w-full ring-0 focus:ring-0 focus:outline-none focus-visible:ring-0 focus-visible:outline-none',
											)}
											value={item?.testValue as string}
											onChange={(e: any) =>
												handlePayloadInputTestValue(
													e.target.value,
													index,
												)
											}
											disabled={item.isConstant}
										/>
									)}
								</div>

								{formErrors[item.key] ? (
									<p className="text-xs text-destructive mt-1 text-right">
										{formErrors[item.key]}
									</p>
								) : null}
							</div>
						))
					: null}

				<div className=" ">
					{formFields?.isApiTestDone ? (
						<>
							<div className="my-4">
								{formFields?.isApiSchemaValid ? (
									<Badge
										variant="secondary"
										className="bg-green-100 text-green-900 hover:bg-green-100"
									>
										Valid Response Type
									</Badge>
								) : (
									<div className="space-y-1 flex items-center flex-wrap">
										<Badge variant="destructive">
											Invalid Response Type
										</Badge>
										<p className="text-xs text-slate-600">
											API response doesn't match required
											format. Please modify API or format api
											response with inline code editor in next
											step.
											<Link
												to={links?.docs?.apiCreation}
												target="_blank"
												className="text-sm font-normal text-normal text-muted-foreground hover:text-primary/80 hover:underline"
											>
												<i className="bi-question-circle ms-1 cursor-pointer"></i>
											</Link>
										</p>
									</div>
								)}
							</div>
							{typeof formFields?.responseSchema === 'string' ? (
								<Textarea
									className="w-full h-32 bg-muted"
									value={formFields?.responseSchema}
									readOnly
								/>
							) : (
								<pre className="h-fit max-w-[500px] overflow-auto relative rounded bg-muted min-w-full w-fit p-4 font-mono text-sm font-medium">
									{JSON.stringify(
										formFields?.responseSchema,
										null,
										2,
									)}
								</pre>
							)}
						</>
					) : null}
				</div>
			</div>
		</div>
	);
};
