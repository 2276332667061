import { Media } from '@/components/element/media/Media';
import { Button } from '@/components/ui/button';
import { asset } from '@/config/asset-cdn';
import analytics from '@/lib/analytics';
import { cn } from '@/lib/utils';
import { TrackingEvents } from '@/types/tracking.type';
import { useEffect } from 'react';
import en from '../../../../locales/en.json';
import { queryClient } from '@/lib/react-query';
import { getLoyaltyCampaign } from '@/features/loyalty-campaigns/services/loyalty.service';
import { handleErrorMessage } from '@/utils/notifications';

const EarnSection = ({ getTaskCompletedStatus, user }) => {
	const { intractCredits } = en;
	const section = intractCredits?.sections?.earn;

	const handleAction = (action: string) => {
		if (action === 'Claim') {
			// open dialogs
		} else if (action === 'Create Quest') {
			window.open('/app/campaign/quests/new', '_blank');
		}
	};

	useEffect(() => {
		const updateData = async () => {
			try {
				await queryClient.invalidateQueries({
					queryKey: ['campaign', 'loyalty-quests'],
				});
				const newData = await getLoyaltyCampaign();

				queryClient.setQueryData(['campaign', 'loyalty-quests'], newData);
			} catch (error) {
				handleErrorMessage(error);
			}
		};

		updateData();
	}, []);

	useEffect(() => {
		analytics.track(TrackingEvents.CreditsEarnViewed, {
			userId: user?._id,
			enterpriseId: user?.enterpriseId,
		});
	}, [user]);

	return (
		<div className="flex flex-col rounded-xl p-6 border border-slate-200 ">
			<div className="">
				<h2 className="text-slate-700 self-stretch text-lg">
					{section?.title}
				</h2>
				<p className="text-slate-500 self-stretch text-sm leading-5">
					{section?.subtitle}
				</p>
			</div>
			<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-4 mt-6">
				{mockData.map((benefit, index) => (
					<div
						key={benefit._id}
						className={cn(
							'py-4 px-3.5 border border-slate-200 rounded-lg',
							benefit.comingSoon &&
								'bg-gradient-to-b from-transparentWhite via-transparentWhite to-transparentBlue opacity-80',
						)}
					>
						{benefit.comingSoon ? (
							<div className="text-center mx-auto">
								<Media
									src={asset['credit-coming-soon']}
									altText="coming-soon"
									className="text-center mx-auto"
								/>
								<p className="text-sm text-slate-600 font-medium mt-1">
									More Coming Soon!
								</p>
							</div>
						) : (
							<div className="flex flex-col h-full flex-1">
								<div className="mb-2">
									{benefit?.icon?.startsWith('http') ? (
										<Media
											src={benefit.icon}
											altText={benefit.title}
											className="size-5"
										/>
									) : (
										<i
											className={`bi bi-${benefit.icon} text-slate-700 text-xl leading-5 size-5`}
										></i>
									)}
								</div>
								<h3 className="text-slate-700 text-base font-normal mb-1">
									{benefit.title}
								</h3>
								<p className="text-slate-500 text-sm font-light mb-4">
									{benefit.description}
								</p>
								<div className="flex items-center">
									{benefit.credits && (
										<span className="text-slate-500 font-medium me-1.5">
											+
										</span>
									)}
									<div className="flex items-center justify-between mt-auto w-full">
										{benefit.credits && (
											<div className="flex items-center gap-1 bg-slate-100 rounded-full">
												<Media
													src={
														asset['credit-icon-primary']
													}
													altText="credit-icon"
													className="size-[22px]"
												/>
												<span className="text-slate-500 text-sm leading-5 tracking-[0.2px] font-normal py-1 pr-2">
													{benefit.credits}
												</span>
											</div>
										)}

										<Button
											className={`px-4 py-2 rounded-lg text-sm`}
											variant="outline"
											disabled={getTaskCompletedStatus(index)}
											onClick={() =>
												handleAction(benefit.action)
											}
										>
											{getTaskCompletedStatus(index) ? (
												<span>
													{section?.buttons?.claimed}
													<i className="bi bi-check-circle-fill text-violet-500 ms-2"></i>
												</span>
											) : (
												benefit.action
											)}
										</Button>
									</div>
								</div>
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default EarnSection;

const mockData = [
	{
		_id: 1,
		title: 'Sign-Up on Intract',
		description:
			'Your campaign featured on the primary real-estate of Intract’s homepage',
		credits: 5000,
		icon: 'person',
		action: 'Claim',
	},
	{
		_id: 2,
		title: 'Publish Your First Quest',
		description:
			'Your campaign featured on the primary real-estate of Intract’s homepage',
		credits: 5000,
		icon: 'compass',
		action: 'Create Quest',
	},
	{
		_id: 3,
		title: '',
		description: '',
		icon: '',
		comingSoon: true,
	},
];
