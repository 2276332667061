import LineTabs from '@/components/element/tabs/LineTabs';
import PageHeader from '@/components/layout/PageHeader';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/hooks/useAuth';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { useEffect } from 'react';
import en from '../../../locales/en.json';
import useCreditsNux from '../hooks/useCreditsNux';
import useCreditsOverview from '../hooks/useCreditsOverview';
import CreditNux from './modals/CreditNux';
import BenefitsSection from './sections/BenefitsSection';
import EarnSection from './sections/EarnSection';
import HistorySection from './sections/HistorySection';
import StatsCard from './sections/StatsCard';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';

const CreditsOverview = () => {
	const { intractCredits } = en;
	const {
		selected,
		setSelected,
		getIsDisabled,
		renderTooltip,
		creditBenefits,
		isLoading,
		handleTabChange,
	} = useCreditsOverview();
	const {
		open,
		setOpen,
		step,
		setStep,
		handleNext,
		handlePrevious,
		getTaskCompletedStatus,
		handleOpenNux,
		handleCloseNux,
	} = useCreditsNux();

	const { user } = useAuth();

	useEffect(() => {
		analytics.track(TrackingEvents.CreditsPageViewed, {
			userId: user?._id,
			enterpriseId: user?.enterpriseId,
		});
	}, [user]);

	if (isLoading) return <FullPageLoading />;

	return (
		<div className="space-y-6">
			<PageHeader
				title={intractCredits?.title}
				description={intractCredits?.subtitle}
				learn=""
			>
				<Button onClick={handleOpenNux} variant="outline" className="-mr-4">
					<i className="bi-info-circle me-2"></i>
					{intractCredits?.learnMore}
				</Button>
			</PageHeader>
			<StatsCard />
			<LineTabs
				options={[
					{ label: intractCredits?.tabs?.benefits, value: 'benefits' },
					{ label: intractCredits?.tabs?.earn, value: 'earn' },
					{ label: intractCredits?.tabs?.history, value: 'history' },
				]}
				selected={selected}
				setSelected={(newVal) => handleTabChange(newVal)}
				className="mt-6 flex space-x-8"
			/>
			<div>
				{selected === 'benefits' && (
					<BenefitsSection
						getIsDisabled={getIsDisabled}
						renderTooltip={renderTooltip}
						creditBenefits={creditBenefits?.consumeCredits}
						isLoading={isLoading}
						user={user}
					/>
				)}
				{selected === 'earn' && (
					<EarnSection
						getTaskCompletedStatus={getTaskCompletedStatus}
						user={user}
					/>
				)}
				{selected === 'history' && <HistorySection user={user} />}
			</div>
			<CreditNux
				open={open}
				setOpen={setOpen}
				step={step}
				setStep={setStep}
				handleNext={handleNext}
				handlePrevious={handlePrevious}
				getTaskCompletedStatus={getTaskCompletedStatus}
				handleCloseNux={handleCloseNux}
			/>
		</div>
	);
};

export default CreditsOverview;
