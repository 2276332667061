import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Select, SelectContent, SelectItem } from '@/components/ui/select';
import { useCreateCampaignTasks } from '@/features/campaigns/hooks/create/useCreateCampaignTasks';
import { ICreateTasks } from '@/features/campaigns/types';
import { MetricBasedXpFunction } from '@/features/campaigns/types/tasks.enums';
import { uploadFile } from '@/services/utility.service';
import { convertCamelCaseToSentence } from '@/utils/parsers';
import { CaretSortIcon } from '@radix-ui/react-icons';
import { SelectTrigger, SelectValue } from '@radix-ui/react-select';
import { useMemo, useRef, useState } from 'react';
import { toast } from 'sonner';

interface ITaskHeaderProps extends ICreateTasks {
	isReordering: boolean;
	isEditingTitle: boolean;
	setIsEditingTitle: any;
	taskIndex: number;
}

const TaskHeader = ({
	isReordering,
	isEditingTitle,
	setIsEditingTitle,
	taskIndex,
	...task
}: ITaskHeaderProps) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const [fileLink, setFileLink] = useState<string | null>(
		task?.logo?.type === 'image' ? task?.logo?.value : null,
	);

	const { moveTask, editTask } = useCreateCampaignTasks();

	const uploadTaskIcon = async (file: any) => {
		return new Promise((resolve) => {
			toast.promise(
				Promise.all([
					uploadFile(file).then((res) => {
						resolve(res);
						editTask(task.taskId, 'EDIT_TASK_LOGO', {
							type: 'image',
							value: res,
						});
					}),
				]),
				{
					loading: 'Uploading icon...',
					success: 'Task icon uploaded successfully',
					error: 'Failed to upload',
				},
			);
		});
	};

	const isMoveDisabled = () => {
		if (taskIndex === 0) return true;
	};

	return (
		<div
			className={`flex items-center justify-between px-4 py-4 bg-slate-50 ${
				!isReordering && task?.isEditing ? 'rounded-t-lg' : 'rounded-lg'
			} border border-slate-300  transition-all duration-700`}
		>
			<div className="flex-grow pe-5">
				<div className=" flex items-center ">
					<div className=" border rounded-full flex items-center h-10 w-10 justify-center me-2 relative">
						<div
							className="absolute bottom-0 cursor-pointer right-0 size-5 bg-white border flex items-center justify-center -m-1 rounded-full"
							onClick={() => {
								inputRef.current.click();
							}}
						>
							<i className="bi-pencil-fill text-[10px] cursor-pointer"></i>
						</div>
						{fileLink ? (
							<img src={fileLink} className="size-10 rounded-full" />
						) : (
							task?.logo?.type === 'icon' && (
								<i
									className={`bi ${task.logo?.value} text-blue-600 text-lg`}
								></i>
							)
						)}
					</div>
					<input
						type="file"
						className="absolute top-0 w-0 -z-1 opacity-0"
						ref={inputRef}
						onChange={(e) => {
							const file = e.target.files[0];
							setFileLink(
								URL.createObjectURL(inputRef.current.files[0]),
							);
							uploadTaskIcon(file);
						}}
						accept={'image/*'}
					/>
					<div className="flex-grow max-w-[22rem]">
						<div className="text-[10px] text-muted-foreground rounded-full px-2 border bg-slate-200 inline-flex text-slate-800 font-medium">
							{convertCamelCaseToSentence(task.templateType)}
						</div>
						<div className="flex items-center mt-0">
							<div className="font-medium text-sm">
								{task.description}
							</div>
							{!isReordering && task.isEditing && (
								<div className="ms-2 cursor-pointer">
									{isEditingTitle ? (
										<>
											<i
												className="text-xs  bi-check-circle-fill "
												onClick={() =>
													setIsEditingTitle(false)
												}
											></i>
										</>
									) : (
										<>
											<i
												className="text-xs  bi-pencil-fill text-slate-400 "
												onClick={() => {
													setIsEditingTitle(true);
												}}
											></i>
										</>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			<div className="flex items-center  min-w-[130px] justify-end">
				{task?.metricBasedXpConfig?.functionType &&
				(task?.metricBasedXpConfig?.functionType ===
					MetricBasedXpFunction.None ||
					task?.metricBasedXpConfig?.functionType ===
						MetricBasedXpFunction.Tiered) ? (
					<SelectXP task={task} />
				) : null}
				{/* {task.isEditing ? ( */}
				<div className="space-x-2">
					<TooltipWrapper tooltip={'Move this task up'} align="end">
						<Button
							size="icon"
							variant="outline"
							className="size-6 bg-secondary hover:border-slate-400 hover:bg-slate-100"
							onClick={() => moveTask(task?.taskId, 'up')}
							// disabled={!!isMoveDisabled}
						>
							<i className="bi-chevron-up"></i>
						</Button>
					</TooltipWrapper>

					<TooltipWrapper tooltip={'Move this task down'} align="end">
						<Button
							size="icon"
							variant="outline"
							className="size-6 bg-secondary hover:border-slate-400 hover:bg-slate-100"
							onClick={() => moveTask(task?.taskId, 'down')}
						>
							<i className="bi-chevron-down"></i>
						</Button>
					</TooltipWrapper>
				</div>
				{/* ) : null} */}
			</div>
		</div>
	);
};

export default TaskHeader;

const SelectXP = ({ task }: { task: ICreateTasks }) => {
	const [isOpen, setIsOpen] = useState(false);

	const { editTask } = useCreateCampaignTasks();
	const options = useMemo(() => {
		if (task.minXp && task.maxXp) {
			const length = Math.ceil((task.maxXp - task.minXp + 1) / 5);
			return Array.from({ length }, (_, i) => {
				const value = i * 5 + task.minXp;
				return { value, label: value };
			});
		}
		return [];
	}, [task]);

	if (!options.length)
		return (
			<Badge
				variant="secondary"
				className="border-slate-200 hover:bg-slate-100 hover:border-slate-400 me-2 px-2 text-xs leading-5 h-6 shadow-sm"
			>
				{task.xp} XP
			</Badge>
		);
	return (
		<Select
			value={task.xp?.toString()}
			onValueChange={(v) => {
				editTask(task.taskId, 'EDIT_XP', Number(v));
			}}
			onOpenChange={(open) => setIsOpen(open)}
		>
			<SelectTrigger>
				<Badge
					variant="secondary"
					className="border-slate-200 hover:bg-slate-100 hover:border-slate-400 me-2 pe-1 px-2 text-xs leading-5 h-6 shadow-sm"
				>
					<SelectValue />
					{/* <Separator orientation="vertical" className="mx-1" /> */}
					{/* <i
						className={`bi-chevron-down h-4 w-4 transition-transform duration-400 ${
							isOpen ? 'rotate-180 mt-0.5' : 'mb-0.5'
						}`}
					></i> */}
					<CaretSortIcon className="h-4 w-4 transition-transform duration-400 ms-0.5" />
				</Badge>
			</SelectTrigger>
			<SelectContent>
				{options.map((i) => (
					<SelectItem key={i.value} value={i.value?.toString()}>
						{i.label} XP
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
};
