import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { toast } from 'sonner';
import { CreateTaskAPISteps, TaskAPIStatus } from '../types/task-apis.enum';
import { deleteTaskAPI } from '../services/task-apis.service';
import { queryClient } from '@/lib/react-query';
import { useMutation } from '@tanstack/react-query';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { useConfirm } from '@/hooks/useConfirm';
import { IApiRequest } from '../types/task-apis.type';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import { useConnectAPI } from './useConnectAPI';
import { useMemo } from 'react';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';

const useTaskAPITable = ({ setShowNew }: { setShowNew?: any }) => {
	const { show } = useConfirm();
	const { setFormFields, setStep } = useConnectAPI();

	const deleteApiMutation = useMutation({
		mutationFn: deleteTaskAPI,
		onSuccess: () => {
			toast.success('API deleted successfully');
			queryClient.invalidateQueries({ queryKey: ['api-requests'] });
		},
	});

	const copyText = (endpoint: string, name: string) => {
		navigator.clipboard.writeText(endpoint);
		toast.success(
			`${name ? name : 'API endpoint'} has been copied on your clipboard`,
		);
	};

	const editApi = (data: IApiRequest, step: CreateTaskAPISteps) => {
		setStep(step);
		const formatData = (payload: IApiRequest['apiDataFields']) => {
			const tempPayload: any = [];
			Object.entries(payload).map(([key, value]) =>
				tempPayload.push({
					key: key,
					value: value,
					testValue: '',
				}),
			);
			return tempPayload;
		};
		const formatCustomData = (payload: IApiRequest['apiConstantDataFields']) => {
			const tempPayload: any = [];
			Object.entries(payload).map(([key, value]) =>
				tempPayload.push({
					key: key,
					value: value,
					testValue: '',
				}),
			);
			return tempPayload;
		};
		const formatHeaders = (headers: IApiRequest['apiHeaders']) => {
			const tempHeaders: any = [];
			Object.entries(headers).map(([key, value]) =>
				tempHeaders.push({
					key,
					value,
				}),
			);
			return tempHeaders;
		};

		setFormFields((prev: any) => ({
			...prev,
			_id: data?._id,
			apiName: data?.apiName,
			apiEndpoint: data?.apiEndpoint,
			apiMethod: data?.apiMethod,
			apiPayloadMethod: data?.apiDataPassingMethod,
			apiVerificationAction: data?.apiVerificationFor,
			isHeaderRequired: data?.isHeaderRequired, //TODO: check headers length  ?
			apiPayload: data?.apiDataFields
				? formatData(data?.apiDataFields)
				: [
						{
							key: 'wallet',
							value: 'address',
							testValue: '',
						},
					],
			apiHeaders: data?.apiHeaders
				? formatHeaders(data?.apiHeaders)
				: [
						{
							key: 'Authorization',
							value: '',
						},
					],
			isRecurringTasksEnabled: data?.isRecurringTaskEnabled,
			isMetricBasedTaskEnabled: data?.isMetricBasedTaskEnabled,
			apiCredentialsType: data?.apiCredentialsType,
			isCustomConstantPayloadRequired:
				Array.isArray(data?.apiConstantDataFields) &&
				data.apiConstantDataFields.length > 0,
			apiConstantDataFields: data?.apiConstantDataFields
				? formatCustomData(data?.apiConstantDataFields)
				: [],
			graphQlQuery: data?.graphQlQuery || '',
			apiOutputExpressions: data?.apiOutputExpressions,
		}));
		analytics.track(TrackingEvents.TaskAPIsCreateViewed, {
			isUpdate: true,
			apiId: data._id,
			apiName: data.apiName,
		});
		setShowNew(true);
	};

	const deleteConfirmation = async (data: any) => {
		const res = await show({
			title: 'Delete API',
			subtitle: `Are you sure you want to delete ${data.apiName} API?`,
			confirmText: 'Delete',
		});
		if (!res) return;
		await deleteApiMutation.mutateAsync(data);
	};

	const options = (row: any) => [
		{
			type: 'button',
			label: 'Edit',
			disabled: row?.original?.campaigns?.length > 0,
			onClick: () => editApi(row.original, CreateTaskAPISteps.Basic),
			tooltip:
				'You can not edit Task APIs which are being used in active campaigns. If there are issues with this API, you can connect a new API and change the API being used in the respective campaign tasks.',
		},
		{
			type: 'button',
			label: 'Test',
			onClick: () => editApi(row.original, CreateTaskAPISteps.ReTesting),
		},
		{
			type: 'separator',
		},
		{
			type: 'button',
			label: 'Delete',
			onClick: () => deleteConfirmation(row.original),
		},
	];

	const taskApiColumns: ColumnDef<IApiRequest>[] = [
		{
			accessorKey: 'apiName',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Name"
					className="px-1"
				/>
			),
			cell: ({ row }) => <div className="p-1">{row?.original?.apiName}</div>,
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'apiEndpoint',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Endpoint" />
			),
			cell: ({ row }) => (
				<span className="font-normal truncate text-ellipsis text-xs rounded-sm px-3 py-2 bg-muted">
					<span className="me-2 font-semibold">
						{row?.original?.apiMethod}
					</span>
					{row?.original?.apiEndpoint}
					<Button
						variant="ghost"
						size="sm"
						className="ml-2 p-0"
						onClick={() =>
							copyText(
								row?.original?.apiEndpoint,
								row?.original?.apiName,
							)
						}
					>
						<i className="bi-clipboard-fill text-muted-foreground"></i>
					</Button>
				</span>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'status',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Status" />
			),
			cell: ({ row }) => (
				<div className="space-x-2 flex items-center">
					{row?.original?.campaigns?.length > 0 ? (
						<StatusBadge status={'ACTIVE'} className="text-xs" />
					) : (
						<StatusBadge
							status={row?.original?.status}
							className="text-xs"
						/>
					)}
					{row?.original?.status === TaskAPIStatus.Rejected ? (
						<Badge
							variant="destructive"
							className="cursor-pointer"
							onClick={() =>
								toast.error(`API Rejection Reason`, {
									description: ` ${row.original.apiName} API was rejected due to ${row.original.errorMessage}`,
									action: <Button onClick={() => {}}>OK</Button>,
								})
							}
						>
							Check Error
						</Badge>
					) : null}
				</div>
			),
			enableSorting: true,
		},
		{
			id: 'actions',
			cell: ({ row }) => {
				return (
					<DataTableRowActions row={row} options={options(row) as any} />
				);
			},
			enableSorting: false,
			enableHiding: false,
		},
	];

	return {
		taskApiColumns,
	};
};

export default useTaskAPITable;
