import { SelectNetwork2 } from '@/components/element/inputs/SelectNetworks2';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';
import { useMemo } from 'react';
import { ITaskInputsProps } from './TaskInputs';

const TaskInputSelectNetwork = ({
	adminInput,
	taskId,
	editTaskAdminInput,
	error,
}: ITaskInputsProps) => {
	const { chains } = useGetSupportedChains({
		showAdditionalChains: false,
	});

	const allowedChains = useMemo(() => {
		if (!chains) return [];
		if (adminInput.optionsFrom === 'with-rpc-options') {
			return chains?.filter((chain) => chain.isRpcUrlsAvailable);
		}
		if (adminInput.optionsFrom === 'with-moralis-options') {
			return chains?.filter((chain) => chain.isMoralisAvailable);
		}
		return []
	}, [chains, adminInput.optionsFrom]);

	return (
		<>
			<div className="relative">
				<div className="text-sm font-medium mb-1">{adminInput.label}</div>
				{adminInput.description && (
					<div className="text-xs text-muted-foreground mb-1">
						{adminInput.description}
					</div>
				)}
				<SelectNetwork2
					value={adminInput.value as number}
					setValue={(e) => {
						editTaskAdminInput(taskId, adminInput.key, e.chainId as any);
					}}
					allowedChainIds={
						allowedChains?.map((chain) => Number(chain?.chainId)) ?? []
					}
					error={error}
					errorMsg="Please select a network"
					defaultAll={false}
					withoutPortal
				/>
			</div>
		</>
	);
};

export default TaskInputSelectNetwork;
