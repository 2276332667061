import InputTextArea from '@/components/element/inputs/InputTextArea';
import { IAPIFormFields, ParsedData } from '../types/task-apis.type';
import { ApiCredentialTypes, DataPassingMethod } from '../types/task-apis.enum';
import { useCurlParser } from '../hooks/useCurlParser';
import { Label } from '@/components/ui/label';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';

const CurlParser = ({
	formFields,
	setFormFields,
	formErrors,
	setFormErrors,
	parsedData,
	setParsedData,
}: {
	formFields: IAPIFormFields;
	setFormFields: (fields: IAPIFormFields) => void;
	formErrors: any;
	setFormErrors: Dispatch<SetStateAction<any>>;
	parsedData: ParsedData;
	setParsedData: Dispatch<SetStateAction<ParsedData>>;
}) => {
	const { error, getPlaceholder } = useCurlParser(
		formFields,
		setFormFields,
		parsedData,
		setParsedData,
	);

	return (
		<div className="max-w-2xl">
			<div className="w-full flex justify-between items-center">
				<Label>
					{formFields?.apiCredentialsType === ApiCredentialTypes.Rest
						? `Enter your RESTful API cURL command`
						: `Enter your GraphQL cURL command`}
				</Label>
			</div>
			<InputTextArea
				className="w-full rounded my-4"
				value={formFields?.curlCommand}
				setValue={(value) => {
					setFormFields({
						...formFields,
						curlCommand: value,
						apiConstantDataFields: [],
						apiPayload: [],
						isCustomConstantPayloadRequired: false,
					});
					setParsedData(null);
				}}
				placeholder={getPlaceholder()}
				error={!!error || !!formErrors?.curlCommand}
				errorText={error || formErrors?.curlCommand}
				label=""
				inputClassName="h-[200px]"
				labelClassName="mt-2 mb-4"
			/>

			{parsedData && (
				<div className="mt-4">
					<h2 className="font-medium text-sm mb-2">Parsed Data:</h2>
					<pre className="bg-gray-100 text-sm p-4 rounded overflow-x-auto">
						{JSON.stringify(parsedData, null, 2)}
					</pre>
				</div>
			)}
		</div>
	);
};

export default CurlParser;
