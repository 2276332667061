import { Dispatch, SetStateAction, createContext, useState } from 'react';
import { ErrorsState, IAPIFormFields, ParsedData } from '../types/task-apis.type';
import {
	ApiCredentialTypes,
	ApiDataEnums,
	CreateTaskAPISteps,
} from '../types/task-apis.enum';

const defaultValue: IAPIFormFields = {
	apiName: '',
	apiMethod: 'POST',
	apiEndpoint: '',
	apiPayloadMethod: 'BODY',
	apiVerificationAction: 'evm',
	apiPayload: [
		{
			key: 'address',
			value: ApiDataEnums.WalletAddress,
			testValue: '',
		},
	],
	apiHeaders: [
		{
			key: 'Authorization',
			value: '',
		},
	],
	isHeaderRequired: false,
	isRecurringTasksEnabled: false,
	isMetricBasedTaskEnabled: false,
	isApiTestDone: false,
	isApiSchemaValid: false,
	responseSchema: {},
	_id: '',
	apiCredentialsType: ApiCredentialTypes.Rest,
	apiConstantDataFields: [],
	graphQlQuery: '',
	apiOutputExpressions: {
		result: {
			expression: 'return response?.data?.result',
		},
		code: {
			expression: '',
		},
		message: {
			expression: '',
		},
	},
};

interface ConnectAPIState {
	formFields: IAPIFormFields;
	setFormFields: Dispatch<SetStateAction<IAPIFormFields>>;
	formErrors: any;
	setFormErrors: any;
	resetForm: any;
	isLoading: boolean;
	setIsLoading: any;
	step: CreateTaskAPISteps;
	setStep: Dispatch<SetStateAction<CreateTaskAPISteps>>;
	parsedData: ParsedData;
	setParsedData: Dispatch<SetStateAction<ParsedData>>;
	evaluationErrors: ErrorsState;
	setEvaluationErrors: Dispatch<SetStateAction<ErrorsState>>;
	evaluatedFields: Record<string, boolean>;
	setEvaluatedFields: Dispatch<SetStateAction<Record<string, boolean>>>;
	dialogMaxWidth: string;
	setDialogMaxWidth: Dispatch<SetStateAction<string>>;
}

const initialState: ConnectAPIState = {
	formFields: defaultValue,
	setFormFields: () => {},
	formErrors: {},
	setFormErrors: () => {},
	resetForm: () => {},
	isLoading: false,
	setIsLoading: () => {},
	step: CreateTaskAPISteps.Basic,
	setStep: () => {},
	parsedData: null,
	setParsedData: () => {},
	evaluationErrors: {},
	setEvaluationErrors: () => {},
	evaluatedFields: {},
	setEvaluatedFields: () => {},
	dialogMaxWidth: '35rem',
	setDialogMaxWidth: () => {},
};

export const ConnectAPIContext = createContext<ConnectAPIState>(initialState);

export function ConnectAPIProvider({ children }) {
	const [formFields, setFormFields] = useState<IAPIFormFields>(defaultValue);
	const [formErrors, setFormErrors] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);
	const [step, setStep] = useState<CreateTaskAPISteps>(CreateTaskAPISteps.Basic);
	const [parsedData, setParsedData] = useState<ParsedData | null>(null);
	const [evaluationErrors, setEvaluationErrors] = useState<ErrorsState>({});
	const [evaluatedFields, setEvaluatedFields] = useState<Record<string, boolean>>(
		{},
	);
	const [dialogMaxWidth, setDialogMaxWidth] = useState(
		initialState.dialogMaxWidth,
	);

	const resetForm = () => {
		setFormFields(defaultValue);
		setFormErrors({});
		setStep(CreateTaskAPISteps.Basic);
		setParsedData(null);
		setEvaluationErrors({});
		setEvaluatedFields({});
		setDialogMaxWidth(initialState.dialogMaxWidth);
	};

	return (
		<ConnectAPIContext.Provider
			value={{
				formFields,
				setFormFields,
				formErrors,
				setFormErrors,
				resetForm,
				isLoading,
				setIsLoading,
				step,
				setStep,
				parsedData,
				setParsedData,
				evaluationErrors,
				setEvaluationErrors,
				evaluatedFields,
				setEvaluatedFields,
				dialogMaxWidth,
				setDialogMaxWidth,
			}}
		>
			{children}
		</ConnectAPIContext.Provider>
	);
}
